require('./bootstrap');

window._ = require('lodash');
window.Popper = require('popper.js').default;
require('jquery');
require('bootstrap');


import $ from 'jquery';
window.$ = window.jQuery = $;

import bsCustomFileInput from 'bs-custom-file-input'

$(document).ready(function () {
  bsCustomFileInput.init()
})

$('form.delete').submit(function(event){
 if(!confirm("Are You Sure?")){
    event.preventDefault();
  }
});

$(function() {
    $('[data-toggle="popover"]').popover();
})

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

import 'jquery-ui/ui/widgets/sortable.js';


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});