window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

$(function() {
    $('[data-toggle="popover"]').popover();
})

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
